.footer {
	background: $white;

	.company {
		grid-area: company;
		align-self: center;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		gap: 5px;
		grid-template-areas:
			"logo"
			"company-info"
			"copyright"
			"footer-menu";

		@include mq-above(xs) {
			grid-template-columns: 80px 1fr;
			gap: 0;
			grid-template-areas:
				"logo company-info"
				"logo copyright"
				"logo footer-menu";
		}

		.logo {
			grid-area: logo;
			align-self: center;
			background: url(../../img/footer-logo.svg) no-repeat;
			height: 60px;
			width: 60px;
			margin-bottom: 1rem;
		}

		.company-info {
			p {
				font-weight: 700;

				@include mq-above(xs) {
					display: inline-block;
				}
			}

			@include mq-below(xs) {
				span:first-of-type {
					&::after {
						content: ", ";
					}
				}
			}

			@include mq-above(xs) {
				font-size: unset;

				span {
					font-weight: 400;
					margin-left: 0.5rem;

					&::before {
						content: "\00b7";
						margin-right: 0.5rem;
					}
				}
			}
		}

		.copyright {
			grid-area: copyright;
			font-size: 14px;

			@include mq-above(xs) {
				font-size: unset;
			}
		}

		.footer-menu {
			grid-area: footer-menu;
			display: flex;
			list-style: none;
			font-size: 14px;

			@include mq-above(xs) {
				font-size: unset;
			}

			li {
				font-weight: 400;
				margin-right: 1rem;
				cursor: pointer;
				text-decoration: underline;
			}
		}
	}

	.social-media {
		grid-area: social-media;
		align-self: center;

		@include mq-above(xs) {
			justify-self: right;
		}

		span {
			border: 1px solid $primary;
			border-radius: 50%;
			padding: 15px;
			margin: 10px;
			cursor: pointer;
		}
	}

	.brand-icon {
		margin-left: 10px;
		color: #000300;
	}
}

.header-container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: $white;
	flex-wrap: nowrap;

	.logo {
		background: url(../../img/logo.png) no-repeat;
		background-position: center center;
		background-size: contain;
		width: 150px;
		height: 67px;

		@include mq-above(md) {
			width: 250px;
			height: 67px;
		}
	}

	.search {
		margin-left: 1rem;
		margin-right: 1rem;
		display: flex;
		width: 20rem;
		flex-shrink: 2;
		input[type="search"] {
			height: 40px;
			width: 100%;
			border: none;
			border-bottom: 1px solid;

			&:focus {
				outline: none;
			}

			&:active {
				outline: none;
			}
		}
	}
}

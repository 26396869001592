:root {
	--content-padding-inline: 1rem;
	--content-padding: 1rem;
	@include mq-below(lg) {
		--content-padding-inline: 1rem;
	}
	@include mq-below(md) {
		--content-padding-inline: 1rem;
	}
	@include mq-below(xs) {
		--content-padding-inline: 0.5rem;
		--content-padding: 0.5rem;
	}
}

button {
	font-family: "WorkSans-Regular", sans-serif;
}

li {
	font-family: "WorkSans-Regular", sans-serif !important;
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.content-wrapper-frontpage {
	background: url("../../img/bg.jpg") no-repeat;
	background-position: center bottom;
	background-size: cover;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.background-full-width {
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
}

.background-full-width::before {
	position: absolute;
	content: "";
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: inherit;
	background-color: $white;
}

.content-wrapper {
	align-self: center;
	padding: var(--content-padding) var(--content-padding-inline);
	display: flex;
	flex-wrap: wrap;
	gap: var(--content-padding);
	justify-content: space-between;
	max-width: 1400px;
	width: 100%;

	main {
		display: flex;
		flex: 2;
		min-width: 30rem;

		&#review {
			display: flex;
			flex-direction: column;
			gap: var(--content-padding);
		}
	}
	aside {
		display: flex;
		min-width: 20rem;
		flex: 1;

		#sidebars {
			display: flex;
			flex-direction: column;
			gap: var(--content-padding);

			p {
				margin: 10px 0;
			}
		}
	}

	@media (max-width: 35rem) {
		main {
			min-width: 100%;
		}
		aside {
			min-width: 100%;
		}
	}
}

.container {
	width: 100%;
	padding: 1rem;
	background: $white;
	border-radius: 5px;
}

.skeleton {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0.8;
}

input {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* For WebKit browsers like Chrome and Safari */
input::-webkit-input-placeholder {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* For Firefox */
input::-moz-placeholder {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* For Internet Explorer */
input:-ms-input-placeholder {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

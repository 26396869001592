nav {
	&.navigation {
		grid-area: navigation;
		display: none;
		visibility: hidden;
		background: #fff;
		justify-self: flex-end;

		@include mq-above(md) {
			display: flex;
			visibility: visible;
		}

		ul {
			list-style: none;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			justify-self: flex-end;
			align-items: center;

			li {
				list-style: none;
				padding: 10px 20px;

				a {
					color: $black;
					text-decoration: none;

					&.current-menu-item {
						color: $primary;
					}
				}
			}
		}
	}
}

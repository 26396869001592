.btn {
  border: none;
  color: $white;
  height: 60px;
  width: 120px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active {
    outline: none;
  }

  &.btn-primary {
    background-color: $primary;

    &:hover {
      background-color: lighten($primary, 5);
    }
  }

  &.btn-link {
    background-color: transparent;
    color: $primary;

    &:hover {
      color: lighten($primary, 5);
      text-decoration: underline;
    }
  }
}
#responsesContainer {
	display: flex;
	flex-direction: column;
	gap: var(--content-padding);

	.response {
		display: flex;
		padding: calc(var(--content-padding) * 2);
		border-radius: 5px;
		min-height: 150px;
		position: relative;
		overflow: hidden;
		gap: var(--content-padding);
	}

	.flagged-comment {
		font-style: italic;
		font-weight: 300;
	}

	.rating {
		display: flex;
		justify-self: center;

		.score {
			width: 50px;
			height: 50px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $white;

			&.detractor {
				background-color: $red;
			}

			&.passive {
				background-color: $yellow;
			}

			&.promoter {
				background-color: $green;
			}
		}
	}

	--date-font-size: 1rem;

	.info-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1;
		max-width: 100%;
		position: relative;
		gap: calc(var(--content-padding) / 2);

		.user {
			font-weight: 700;
			font-size: 20px;

			span {
				display: block;
				font-weight: 400;
				font-size: 14px;
			}
		}

		.comment {
			margin-bottom: auto;
		}

		.date {
			text-align: right;
			font-style: italic;
			width: 100%;
			font-size: var(--date-font-size);
		}
	}
}

.scroll-point {
	margin: calc(var(--content-padding) * -1 / 2);
}

.pagination {
	display: flex;
	justify-content: center;
}

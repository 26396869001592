@font-face {
	font-family: "WorkSans-Regular";
	src: url("assets/fonts/WorkSans-Regular.ttf");
}

@font-face {
	font-family: "WorkSans-SemiBold";
	src: url("assets/fonts/WorkSans-SemiBold.ttf");
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0 auto;
	font-family: "WorkSans-Regular", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "WorkSans-SemiBold", sans-serif;
}

.search {
	position: relative;

	.search-result-wrapper {
		width: 100%;
		position: absolute;
		bottom: 0;
		.search-results {
			width: 100%;
			min-width: min-content;
			list-style-type: none;
			text-align: left;
			background: $white;
			position: absolute;
			z-index: 100;
			transform: translate3d(0, 0, 0); // Fix for Safari

			a {
				text-decoration: none;
				color: $black;
			}
		}
	}
}

.search-item {
	background-color: #fafafa;

	padding: 15px 12px;
	transition: 0.1s all ease-in-out;

	&:hover {
		transition: 0.1s all ease-in-out;
		background-color: darken($white, 5%);
		color: $primary;
	}
	&.selected {
		transition: 0.1s all ease-in-out;
		background-color: darken($white, 5%);
		color: $primary;
	}
}

.search-item-divider {
	content: "";
	width: 100%;
	height: 1px;
	background-color: #dedede;
}

input[type="search"] {
	font-size: 1rem;
}

#frontpage {
	max-width: 100%;
	padding: var(--content-padding);
	input[type="search"] {
		width: 300px;
		max-width: 100%;
		height: 80px;
		padding: 12px 20px;
		font-size: 22px;
		border: none;
		border-radius: 10px;

		@include mq-above(md) {
			width: 800px;
		}

		&:focus {
			outline: none;
		}

		&:active {
			outline: none;
		}

		&:not(:placeholder-shown) {
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}
	}

	.search-result-wrapper {
		.search-results {
			width: 300px;
			list-style-type: none;
			text-align: left;
			background: $white;
			position: absolute;

			@include mq-above(md) {
				width: 800px;
			}

			a {
				text-decoration: none;
				color: $black;
			}

			li {
				padding: 15px 12px;
				transition: 0.1s all ease-in-out;

				&:hover {
					transition: 0.1s all ease-in-out;
					background-color: darken($white, 5%);
					color: $primary;
				}
			}
		}
	}
}

#analytics {
	--height: 220px;
	position: relative;
	height: var(--height);
	width: 100%;

	iframe {
		height: var(--height);
		width: 100%;
		border: none;
	}
}
